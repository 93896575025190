import React from 'react';
import { useState } from 'react';
import './NotFoundHandler.css';

import Cookies from 'universal-cookie';

import StringRes from './component/StringResources';
import NotFound from './component/NotFound';
import Topbar from './component/Topbar';
import NavbarElement from './component/Navbar';
import Footer from './component/Footer';

import { Navbar } from 'react-bootstrap';

function NotFoundHandler() {
    const [onTop, setOnTop] = useState(true);
    React.useEffect(() => {
      window.onscroll = () =>{window.scrollY <= 44? setOnTop(true): setOnTop(false);}
      return () => (window.onscroll = null);
    }); 
  
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
  
    const updateWindowDimensions = () => {
      setPageWidth(window.innerWidth);
    };
    React.useEffect(() => {
      // Event listener to update the width when the window size changes
      window.addEventListener('resize', updateWindowDimensions);
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateWindowDimensions);
      };
    }, []);
  
     const cookies = new Cookies(null, { path: '/' });
     var langToken = cookies.get('lang');
     if (!langToken)langToken = 'lt';
     //if (!langToken) langToken = navigator.language || navigator.userLanguage;
    const [lang, setLang] = useState(langToken);
    return (
      <div className='flex-wrapper'>
       <Topbar strings={StringRes(lang)} pageWidth={pageWidth} />
        <NavbarElement strings={StringRes(lang)} redirect={"/"} notFound={true} pageWidth={pageWidth} onTop={onTop} setLang={setLang} lang={lang}/>
      <NotFound strings={StringRes(lang)} onTop={onTop}/>
      <Footer />  
      </div>
    );
  }
  
  export default NotFoundHandler;
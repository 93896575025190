import React from 'react';
import { Col } from "react-bootstrap";
import { FaCircle} from 'react-icons/fa';

function ServiceItem(prop){
    return (
        <Col sm={12}>
        <div className="faq">
        <div className="faq-question"><FaCircle/> {prop.q}</div>
        <div className="faq-answer">{prop.a}</div>
        </div>        
        </Col>
    )
}

export default ServiceItem;
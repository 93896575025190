import React from 'react';
import {Container, Row} from 'react-bootstrap';
import Title from './SectionTitle';
import Item from './FaqItem';

function Section(prop){
    let scrollOffsetClass = "";
    if (prop.onTop && prop.pageWidth<=990){
        scrollOffsetClass = "scroll-offset-fix";
    }

    return (
        <div id="faq" className={scrollOffsetClass} style={prop.section==='dark'?{backgroundColor:'#fef5dd'}:null}>
        <Container className="section-padding">
        <Title title={prop.strings.faq.sectionName}/>
            <Row >
                <Item 
                    q={prop.strings.faq.q1}
                    a={prop.strings.faq.a1}
                />
                <Item 
                    q={prop.strings.faq.q2}
                    a={prop.strings.faq.a2}
                />
                <Item 
                    q={prop.strings.faq.q3}
                    a={prop.strings.faq.a3}
                />
                <Item 
                    q={prop.strings.faq.q4}
                    a={prop.strings.faq.a4}
                />
                 <Item 
                    q={prop.strings.faq.q5}
                    a={prop.strings.faq.a5}
                />
                <Item 
                    q={prop.strings.faq.q6}
                    a={prop.strings.faq.a6}
                />

            </Row>
        </Container>
        </div>
    )
} 

export default Section;
import React from "react";

function Logo(prop){
    var logo = "logo logo-lg";    
    if (prop.pageWidth<400 && prop.pageWidth>310){
        logo = "logo logo-sm";
    }else if(prop.pageWidth<=310){
        logo = "logo logo-xs";
    }
    return (
        <div className={logo}>
            {/* <img style={{height:"25px"}} src="logo_1.jpg" className="rounded-circle w-25" /> */}
            <span >Geltonas </span><span style={{color:"#e1c721"}}>Traliukas </span><span>lt</span>
        </div>
    )
}

export default Logo;
import React from 'react';
import { Col } from "react-bootstrap";


function NotFound (prop) {
    return (
        // <div className="text-center">
        //     <h1 className="section-title" style={{fontSize:"20"}}>{prop.title}</h1>
        //     <div className="section-underline"></div>           
        // </div>
        <div className={!prop.onTop? 'padding-top':''}>
          <div className='text-center visible-screen error-background '>    
               
               <div className=''>
                         <img className='img-fluid error-photo ' src="/not-found.png" alt="lost trasport" />
                         </div>   
                    <div className='error-message'>
                    <h1>{prop.strings.notfound.header}</h1> 
                         <p>{prop.strings.notfound.message}</p><a className='btn btn-secondary' href='/'>{prop.strings.notfound.here}</a>
                    </div>   
               </div>
        </div>
       
    )
}

export default NotFound;
import React from 'react';


function SectionTitle (prop) {
    return (
        <div className="text-center">
            <h1 className="section-title" style={{fontSize:"20"}}>{prop.title}</h1>
            <div className="section-underline"></div>           
        </div>      
    )
}

export default SectionTitle;
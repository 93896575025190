const lit = {
    navbar: {
        service: "Paslaugos",
        faq: "DUK",
        gallery: "Galerija",
        contact: "Kontaktai"
    },
    slides: {
        slideOneHeader: "Pagalba kai jos reikia labiausiai",
        slideOneText: "Mes visada pasiruošę padėti",
        slideTwoHeader: "Draugiškas kolektyvas",
        slideTwoText: "Jei turite klausimų, prašome susisiekti nurodytais kontaktais",
        slideThreeHeader: "Saugus ir kokybiškas pervežimas",
        slideThreeText: "Mes atsakome už savo paslaugų kokybę"
    },
    service: {
        mainText: "Tralo paslaugos Kaune ir visoje Lietuvoje, techninė pagalba kelyje 24/7, mes visada pasiruošę padėti: jei nuleido padangą, o gal nusėdo akumuliatorius, o gal patyrėte avariją kelyje, prašome susisiekti su mumis ir mes padarysime viską, kad padėtume kelyje.",
        callmenow: "Skambinkite mums telefonu",
        mailmenow: "Arba rašykite el. paštu",
        sectionName: "Paslaugos",
        motocycleHeader: "Motociklų",
        motocycleText: "Triračių ir keturačių motociklų pervežimas",
        carHeader: "Lengvųjų automobilių",
        carText: "Lengvųjų automobilių pervežimas",
        offroadHeader: "Visureigių",
        offroadText: "Visureigių iki 3t pervežimas",
        busHeader: "Mikroautobusų",
        busText: "Mikroautobusų iki 3t pervežimas",
        otherHeader: "Kita technika",
        otherText: "Statybinės ir žemės technikos iki 3t pervežimas",
        goodsHeader: "Kroviniai",
        goodsText: "Įvairių krovinių iki 3t pervežimas",
        emergencyHeader: "Kita pagalba",
        emergencyText: "Bet kokia pagalba kelyje, jei nuleido padangą, ar nusėdo akumuliatorius",
        anyTimeHeader: "Bet kuriuo metu",
        anyTimeText: "Pagalba 24 valandas per parą ir 7 dienas per savaitę"
    },
    faq: {
        sectionName: "DUK",
        q1: "Kuriame mieste esate įsikūrę?",
        a1: "Mes esame įsikūrę Kauno mieste.",
        q2: "Ar pagalbą kelyje teikiate tik Kaune?",
        a2: "Paslauga teikiama visoje Lietuvos teritorijoje. Kiekvienas atvejis gali būti individualiai patikslinamas prieš tai susisiekus.",
        q3: "Kokio didžiausio svorio automobilį galite transportuoti?",
        a3: "Šiuo metu yra galimybė transportuoti iki 3t transporto priemones.",
        q4: "Ar yra galimybė transportuoti automobilį su žeminta važiuokle?",
        a4: "Taip, tokia galimybė yra.",
        q5: "Per kiek laiko atvyks pagalba?",
        a5: "Miesto ribose atvykstame vidutiniškai per 30min. Esant už miesto ribų - priklauso nuo atstumo.",
        q6: "Esu serviso/draudimo atstovas ir norėčiau su jumis bendradarbiauti, ar tai būtų įmanoma?",
        a6: "Taip, tai yra įmanoma, prašome susisiekti su mumis dėl daugiau informacijos."
    },
    contact: {
        sectionName: "Kontaktai",
        contactOptions: "Su mumis galite susisiekti žemiau išvarditais būdais",
        phoneNo: "Telefono numeriu:",
        email: "Elektroniniu paštu:",
        other: "Taip pat mus galite rasti:",
        contactForm: "Jeigu turite klausimų ar norite palikti mums žinutę, galite užpildyti žemiau esančią formą",
        namePlaceholder: "Vardas",
        phonePlaceholder: "Telefono numeris",
        messagePlaceholder: "Žinutė",
        send: "Siųsti"
    },
    gallery: {
        sectionName: "Galerija",
        empty: ""
    },
    galleryModal: {
        closeButton: "Uždaryti",
        empty: ""
    },
    topbar: {
        callnow: "Skambinti",
        emailnow: "Rašyti žinutę"
    },
    notfound: {
        header: "404 - Puslapis nerastas",
        message: "Atsiprašome, bet šiuo metu puslapis yra nepasiekiamas. Prašome grįžti į pradinį puslapį",
        here: "Grįžti"
    }
}
const en = {
    navbar: {
        service: "Services",
        faq: "FAQ",
        gallery: "Gallery",
        contact: "Contacts"
    },
    slides: {
        slideOneHeader: " Help when it's needed the most",
        slideOneText: "Please contact to us",
        slideTwoHeader: "Friendly team",
        slideTwoText: "We are to help",
        slideThreeHeader: "Safe and high-quality service",
        slideThreeText: "High standard of quality work"
    },
    service: {
        mainText: "Roadside assistance in Kaunas and throughout Lithuania, technical support on the road 24/7. We are always ready to help: if you have a flat tire, a dead battery, or if you've had a road accident, please contact us, and we will do everything to help you on the road.",
        callmenow: "Call us by phone",
        mailmenow: "Or contact us by email",
        sectionName: "Services",
        motocycleHeader: "Motocycle",
        motocycleText: "Transportation of three-wheelers and four-wheelers motorcycles",
        carHeader: "Cars",
        carText: "Car transportation",
        offroadHeader: "Offroad vehicle",
        offroadText: "Offroad vehicle transportation (up to 3t)",
        busHeader: "Minibuses",
        busText: "Minibuses transportation (up to 3t)",
        otherHeader: "Other vehicles",
        otherText: "Transportation of construction and agricultural machinery (up to 3t)",
        goodsHeader: "Goods",
        goodsText: "Transportation of various goods  (up to 3t)",
        emergencyHeader: "Emergency service",
        emergencyText: "Any assistance on the road if you have a flat tire or your battery dies",
        anyTimeHeader: "Any time",
        anyTimeText: "Assistance 24 hours a day and 7 days a week"
    },
    faq: {
        sectionName: "FAQ",
        q1: "In which city are you located?",
        a1: "We are located in the city of Kaunas.",
        q2: "Do you provide roadside assistance only in Kaunas?",
        a2: "The service is provided throughout the territory of Lithuania. Each case can be individually clarified after contacting us.",
        q3: "What is the maximum weight of a car you can transport?",
        a3: "Currently, it is possible to transport vehicles up to 3 tons.",
        q4: "Is it possible to transport a car with lowered suspension?",
        a4: "Yes, such an option is available.",
        q5: "How long takes until the assistance arrives?",
        a5: "We arrive within an average of 30 minutes within the city limits. Outside the city limits, it depends on the distance.",
        q6: "I am a service/insurance representative and I would like to collaborate with you, would that be possible?",
        a6: "Yes, that is possible, please contact us for more information."
    },
    contact: {
        sectionName: "Contacts",
        contactOptions: "You can contact us through the methods listed below",
        phoneNo: "Phone number:",
        email: "Email:",
        other: "Also you can find us:",
        contactForm: "If you have any questions or want to leave us a message, you can fill out the form below",
        namePlaceholder: "Your name",
        phonePlaceholder: "Your phone number",
        messagePlaceholder: "Your message",
        send: "Send"
    },
    gallery: {
        sectionName: "Gallery",
        empty: ""
    },
    galleryModal: {
        closeButton: "Close",
        empty: ""
    },
    topbar: {
        callnow: "Call us",
        emailnow: "Message us"
    },
    notfound: {
        header: "404 - Page not found",
        message: "Looks like this page is not avalaibe at this time. Please try again later.",
        here: "Back to main page"
    }
}

const rus = {
    navbar: {
        service: "Услуги",
        faq: "Ч.З.В.",
        gallery: "Галерея",
        contact: "Контакты"
    },
    slides: {
        slideOneHeader: " Помощь, когда она нужна больше всего",
        slideOneText: "Мы готовы помочь",
        slideTwoHeader: "Дружелюбная команда",
        slideTwoText: "Если у вас есть вопросы, свяжитесь с нами",
        slideThreeHeader: "Безопасное и качественное обслуживание",
        slideThreeText: "Высокое качество работы"
    },
    service: {
        mainText: "Дорожная помощь в Каунасе и по всей Литве, техническая поддержка на дороге 24/7. Мы всегда готовы помочь: если у вас спущена шина, разрядился аккумулятор или вы попали в дорожное происшествие, свяжитесь с нами, и мы сделаем все, чтобы ваш день не был испорчен.",
        callmenow: "Позвоните нам по телефону",
        mailmenow: "Или свяжитесь с нами по электронной почте",
        sectionName: "Услуги",
        motocycleHeader: "Мотоцикл",
        motocycleText: "Перевозка трехколесных и четырехколесных мотоциклов",
        carHeader: "Автомобили",
        carText: "Перевозка автомобилей",
        offroadHeader: "Внедорожник",
        offroadText: "Перевозка внедорожников (до 3 тонн)",
        busHeader: "Микроавтобусы",
        busText: "Перевозка микроавтобусов (до 3 тонн)",
        otherHeader: "Другие транспортные",
        otherText: "Перевозка строительной и сельскохозяйственной техники (до 3т)",
        goodsHeader: "Товары",
        goodsText: "Перевозка различных товаров (до 3 тонн)",
        emergencyHeader: "Спасательная служба",
        emergencyText: "Помощь при спущенной шине или разряженном аккумуляторе",
        anyTimeHeader: "В любое время",
        anyTimeText: "Помощь круглосуточно, 7 дней в неделю"
    },
    faq: {
        sectionName: "Часто задаваемые вопросы",
        q1: "В каком городе вы находитесь?",
        a1: "Мы находимся в городе Каунас.",
        q2: "Вы предоставляете помощь на дороге только в Каунасе?",
        a2: "Услуга предоставляется на всей территории Литвы. Каждый случай может быть индивидуально уточнен после связи с нами.",
        q3: "Каков максимальный вес автомобиля, который вы можете транспортировать?",
        a3: "В настоящее время возможно транспортировать транспортные средства до 3 тонн.",
        q4: "Возможно ли перевозить автомобиль с опущенной подвеской?",
        a4: "Да, такая опция доступна.",
        q5: "Сколько времени займет, пока помощь прибудет?",
        a5: "Мы прибываем в пределах города в среднем за 30 минут. Вне городских пределов это зависит от расстояния.",
        q6: "Я представитель сервиса/страхования и хотел бы сотрудничать с вами, это возможно?",
        a6: "Да, это возможно, пожалуйста, свяжитесь с нами для получения дополнительной информации."
    },
    contact: {
        sectionName: "Контакты",
        contactOptions: "Вы можете связаться с нами по указанным ниже методам",
        phoneNo: "Номер телефона:",
        email: "Электронная почта:",
        other: "Также вы можете нас найти:",
        contactForm: "Если у вас есть вопросы или вы хотите оставить нам сообщение, вы можете заполнить форму ниже",
        namePlaceholder: "Ваше имя",
        phonePlaceholder: "Ваш номер телефона",
        messagePlaceholder: "Ваше сообщение",
        send: "Отправить"
    },
    gallery: {
        sectionName: "Галерея",
        empty: ""
    },
    galleryModal: {
        closeButton: "Закрыть",
        empty: ""
    },
    topbar: {
        callnow: "Позвоните нам",
        emailnow: "Напишите нам"
    },
    notfound: {
        header: "404 - Страница не найдена",
        message: "Извините, но в данный момент страница недоступна. Пожалуйста, вернитесь на главную страницу.",
        here: "Вернуться"
    }
}

function StringResources(prop){  
    // return lit;

    if (prop.includes('rus')){
        return rus;
    }else if (prop.includes('en')){
        return en;
    }else{
        return lit;
    }  
}

export default StringResources;
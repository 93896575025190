import React from 'react';
import { FaPhone, FaEnvelope, FaAlignJustify } from 'react-icons/fa';

function Fab(prop){
    const [hasMoreButton, setMoreButtons] = React.useState(false);
    function onMoreClick(){
        setMoreButtons(!hasMoreButton);
        
    }
    let hasFab = prop.onTop;
    return (
        hasFab===false&&( 
            <div>
            {/* <div className="fab fab-more" onClick={onMoreClick}><FaAlignJustify /></div> */}
            <div  ><a className="fab fab-call" href="tel:+&#51;&#55;&#48;6&#55;304&#55;2&#55;"><FaPhone /></a></div>
            <div  ><a className="fab fab-more" href="mailto:kaunetraliukas&#64;&#103;&#109;ai&#108;.com"><FaEnvelope /></a></div>
            </div>        
        )   
    )
    // return (
    //     hasFab===false&&(
    //     hasMoreButton===true?(
    //         <div>
    //         {/* <div className="fab fab-more" onClick={onMoreClick}><FaAlignJustify /></div> */}
    //         <div className="fab fab-call" ><a href="tel:+37012345678"><FaPhone /></a></div>
    //         <div className="fab fab-more" ><a href="mailto:kaunetraliukas@gmail.com"><FaEnvelope /></a></div>
    //         </div>

    //     ):<div className="fab fab-more" onClick={onMoreClick}><FaAlignJustify /></div>
    //     )   
    // )
}

export default Fab;
import React from 'react';
import {Container, Row} from 'react-bootstrap';
import Title from './SectionTitle';
import Item from './GalleryItem';

function Section(prop){
    let scrollOffsetClass = "";
    if (prop.onTop && prop.pageWidth<=990){
        scrollOffsetClass = "scroll-offset-fix";
    }

    return (
        <div id="gallery" className={scrollOffsetClass} style={prop.section==='dark'? {backgroundColor:'#fef5dd'}:null} >
        <Container className="section-padding">
        <Title title={prop.strings.gallery.sectionName} />
            <Row >
                <Item 
                    strings={prop.strings}
                    photo="gallery/image 1.jpg"
                    tumb="gallery/image 1 tumb.jpg"
                    alt="gallery photo one" 
                />
                <Item 
                    strings={prop.strings}
                    photo="gallery/image 2.jpg"
                    tumb="gallery/image 2 tumb.jpg"
                    alt="gallery photo two"                    
                />
                 <Item
                    strings={prop.strings} 
                    photo="gallery/image 3.jpg"
                    tumb="gallery/image 3 tumb.jpg"
                    alt="gallery photo three"                   
                />
                <Item
                    strings={prop.strings} 
                    photo="gallery/image 4.jpg"
                    tumb="gallery/image 4 tumb.jpg"
                    alt="gallery photo four"                   
                />
                 <Item
                    strings={prop.strings} 
                    photo="gallery/image 5.jpg"
                    tumb="gallery/image 5 tumb.jpg"
                    alt="gallery photo five"                  
                />
                 <Item
                    strings={prop.strings} 
                    photo="gallery/image 6.jpg"
                    tumb="gallery/image 6 tumb.jpg"
                    alt="gallery photo six"                  
                />               
                 <Item
                    strings={prop.strings} 
                    photo="gallery/image 7.jpg"
                    tumb="gallery/image 7 tumb.jpg"
                    alt="gallery photo seven"                    
                />
                <Item
                    strings={prop.strings} 
                    photo="gallery/image 8.jpg"
                    tumb="gallery/image 8 tumb.jpg"
                    alt="gallery photo eight"                   
                />
                  <Item
                    strings={prop.strings} 
                    photo="gallery/image 9.jpg"
                    tumb="gallery/image 9 tumb.jpg"
                    alt="gallery photo nine"                   
                />
            </Row>
        </Container>
        </div>
    )
} 

export default Section;
import React from 'react';
import { Navbar,Container, Nav, Col} from "react-bootstrap";
import Logo from './Logo';

import Cookies from 'universal-cookie';

function NavbarElement(prop){

  const onLangHandle = (lang) => () => {
    prop.setLang(lang);
    const cookies = new Cookies(null, { path: '/' });
    cookies.set('lang',lang);
  }; 
  let navMenuClass = "";
  let langMenuClass = "d-none d-lg-block text-right";
  let sectMenuClass = "mx-auto text-center";
  if (prop.notFound){
    navMenuClass = sectMenuClass = "hidden";
    langMenuClass = "text-right";
  }

    return (
        <Navbar collapseOnSelect bg="light" expand="lg" fixed={!prop.onTop?'top':null}>
        <Container >        
          <Navbar.Brand href={prop.redirect}><Logo pageWidth={prop.pageWidth}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={navMenuClass}/>          
          <Navbar.Collapse id="basic-navbar-nav" >
          {/* <div className="text-center"> */}
            <Nav className={sectMenuClass}>
              <Nav.Link href="#service">{prop.strings.navbar.service}</Nav.Link>
              {/* <Nav.Link href="#cooporation">Bendradarbiavimas</Nav.Link> */}
              {/* <Nav.Link href="#gallery">Galerija</Nav.Link> */}
              <Nav.Link href="#faq">{prop.strings.navbar.faq}</Nav.Link>
              <Nav.Link href="#gallery">{prop.strings.navbar.gallery}</Nav.Link>
              <Nav.Link href="#contact">{prop.strings.navbar.contact}</Nav.Link>
             <div className='d-block d-lg-none'>---</div>
              <Nav.Item className={prop.lang==='lt' || prop.lang===null || prop.lang === undefined?'hidden':'d-block d-lg-none lang'} onClick={onLangHandle('lt')}>Lietuviškai</Nav.Item>
              <Nav.Item className={prop.lang==='en'?'hidden':'d-block d-lg-none lang'} onClick={onLangHandle('en')}>In English</Nav.Item>
              <Nav.Item className={prop.lang==='rus'?'hidden':'d-block d-lg-none lang'} onClick={onLangHandle('rus')}>По-русски</Nav.Item>


              
            </Nav>
            {/* </div> */}
          </Navbar.Collapse>
         
          <Col className={langMenuClass}>
          <div>
          <span className={prop.lang==='lt' || prop.lang===null || prop.lang === undefined?'lang lang-active':'lang'} onClick={onLangHandle('lt')}>&nbsp;LT&nbsp;</span>|
          <span className={prop.lang==='en'?'lang lang-active':'lang'} onClick={onLangHandle('en')}>&nbsp;EN&nbsp;</span>|
          <span className={prop.lang==='rus'?'lang lang-active':'lang'} onClick={onLangHandle('rus')}>&nbsp;RUS&nbsp;</span>
         </div>
          </Col>
         
          
        </Container>      
      </Navbar>
    )
}

export default NavbarElement;
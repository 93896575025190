import React, { useRef, useState }  from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Title from './SectionTitle';
import { FaFacebookF } from 'react-icons/fa';

import emailjs from '@emailjs/browser';

function Section(prop){
    const form = useRef();
    const [formInput, setFormInput] = useState({
        user_name: "",
        contact_number: "",
        message: ""
    });
    const [disabled, setDisabled] = useState(true);
    
    const sendEmail = (e) => {
        e.preventDefault();
        if (formInput.user_name && formInput.contact_number && formInput.message){
            emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, process.env.REACT_APP_EMAIL_TEMPLATE, form.current, process.env.REACT_APP_EMAIL_KEY)
            .then((result) => {
                console.log(result.text);
                setFormInput({
                  user_name: "",
                  contact_number: "",
                  message: "" 
                })
            }, (error) => {
                console.log(error.text);
            });
        }
       
      };

      function textChange(event){
          setFormInput((prevState) =>({
              ...prevState,
              [event.target.name]:event.target.value}));
              const newObject = {
                ...formInput,
                [event.target.name]:event.target.value};
              
            if (newObject.user_name && newObject.contact_number && newObject.message){
                setDisabled(false);
            }else{
                setDisabled(true);
            }
      }

      let scrollOffsetClass = "";
    if (prop.onTop && prop.pageWidth<=990){
        scrollOffsetClass = "scroll-offset-fix";
    }

    return (
        <div id="contact" className={scrollOffsetClass} style={prop.section==='dark'?{backgroundColor:'#fef5dd'}:null}>
        <Container className="section-padding">
        <Title title={prop.strings.contact.sectionName}/>
            <Row >     
                <div className="contact-info text-center">
                <div>{prop.strings.contact.contactOptions}</div>                
                <div>{prop.strings.contact.phoneNo}</div><div className="contact-number">+370 67304727</div>
                <div>{prop.strings.contact.email}</div> <div className="contact-number">kaunetraliukas@gmail.com</div>
                <div>{prop.strings.contact.other}</div> <div><a href="https://www.facebook.com/Kaunetraliukas.lt"> <FaFacebookF className="greyscale"/></a></div>
                {/* <div>Adresas:</div> <div className="contact-number">Kaunas</div> */}
                </div>
                <div>
                <div className="contact-info text-center">
                <div>{prop.strings.contact.contactForm}</div>
                <form ref={form} onSubmit={sendEmail} className="contact-form">    
                {/* <div > */}
                    <input onChange={textChange}  name="user_name" className="contact-input" placeholder={prop.strings.contact.namePlaceholder} value={formInput.user_name} required></input><br></br>                            
                    <input onChange={textChange} name="contact_number" className="contact-input" placeholder={prop.strings.contact.phonePlaceholder} value={formInput.contact_number} required></input><br></br>
                    <textarea onChange={textChange} name="message" className="contact-input" rows="4" placeholder={prop.strings.contact.messagePlaceholder} value={formInput.message} required></textarea>
                    <div>
                    <Button disabled={disabled} variant="secondary" type="submit"> {prop.strings.contact.send} </Button>
                    </div>
                {/* </div> */}
                </form>
                </div>
                </div>                
            </Row>
        </Container>
        </div>
    )
} 

export default Section;
import React from 'react';
import {Carousel} from 'react-bootstrap';

function MainSection(prop){  

    let headerClass = 'highlight-text-dark header-large-text';
    let textClass = "highlight-text-dark para-normal-text";
    let mainPhoto = "../main_l.jpg";
    let secondPhoto = "../second_l.jpg";
    let thirdPhoto = "../third_l.jpg";

    if (prop.pageWidth < 900 && prop.pageWidth>500){
      mainPhoto = "../main_s.jpg";
      secondPhoto = "../second_s.jpg";
      thirdPhoto = "../third_s.jpg";
      headerClass = 'highlight-text-dark header-medium-text';      
    }else if(prop.pageWidth<=500 && prop.pageWidth>400) {
      mainPhoto = "../main_s.jpg";
      secondPhoto = "../second_s.jpg";
      thirdPhoto = "../third_s.jpg";
      headerClass = 'highlight-text-dark header-small-text';      
    } else if (prop.pageWidth<=400){
      mainPhoto = "../main_s.jpg";
      secondPhoto = "../second_s.jpg";
      thirdPhoto = "../third_s.jpg";
      textClass = "highlight-text-dark para-small-text";
      headerClass = 'highlight-text-dark header-xsmall-text';      
    }

    return (
      <div className={!prop.onTop&&'padding-top'}>
        <Carousel >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={mainPhoto}
              alt="Broken automobile"
            />
            <Carousel.Caption>
              <h3 className={headerClass}>{prop.strings.slides.slideOneHeader}</h3>
              <p  className={textClass}>{prop.strings.slides.slideOneText}</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={secondPhoto}
              alt="Broken automobile"
            />
            <Carousel.Caption>
              <h3 className={headerClass}>{prop.strings.slides.slideTwoHeader}</h3>
              <p className={textClass}>{prop.strings.slides.slideTwoText}</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={thirdPhoto}
              alt="Broken automobile"
            />
            <Carousel.Caption>
              <h3 className={headerClass}>{prop.strings.slides.slideThreeHeader}</h3>
              <p className={textClass}>{prop.strings.slides.slideThreeText}</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>        
    )
}

export default MainSection;
import React from 'react';
import { Col } from "react-bootstrap";

function ServiceItem(prop){
    return (
        <Col sm={6} md={4} lg={3}>
        <div className="service">
        <div className="service-photo"><img src={"../"+prop.photo} alt={prop.alt}></img> </div>
        <div className="service-title">{prop.title}</div>
        <div >
        {prop.description}
        </div>
        </div>
        
        </Col>
    )
}

export default ServiceItem;
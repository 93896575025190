import React from 'react';
import { useState } from 'react';
import { useHistory ,useLocation } from 'react-router-dom';

import './App.css';
import Topbar from './component/Topbar';
import NavbarElement from './component/Navbar';
import Service from './component/ServiceSection';
import Main from './component/MainSection';

import Cooporation from './component/CooporationSection';
import Faq from './component/FaqSection';
import Contact from './component/ContactSection';
import Gallery from './component/GallerySection';
import Fab from './component/Fab';
import Footer from './component/Footer';

import Cookies from 'universal-cookie';

import StringRes from './component/StringResources';

function App() {
  const scrollYOffset = 44;
  const [onTop, setOnTop] = useState(true);
  React.useEffect(() => {
    window.onscroll = () =>{window.scrollY <= scrollYOffset? setOnTop(true): setOnTop(false);}
    return () => (window.onscroll = null);
  }); 

  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    setPageWidth(window.innerWidth);
  };
  React.useEffect(() => {
    // Event listener to update the width when the window size changes
    window.addEventListener('resize', updateWindowDimensions);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  
   const cookies = new Cookies(null, { path: '/' });
   var langToken = cookies.get('lang');
   if (!langToken)langToken = 'lt';
   //if (!langToken) langToken = navigator.language || navigator.userLanguage;
  const [lang, setLang] = useState(langToken);
  return (
    <div className='flex-wrapper'>
    <Topbar strings={StringRes(lang)} pageWidth={pageWidth} />
    <NavbarElement strings={StringRes(lang)} pageWidth={pageWidth} redirect={"#"} onTop={onTop} setLang={setLang} lang={lang}/>
    <Main strings={StringRes(lang)} pageWidth={pageWidth} onTop={onTop}/>
    {/* <MainMobileSection onTop={onTop}  pageWidth={pageWidth} /> */}
    <Fab  onTop={onTop}/>
    <Service strings={StringRes(lang)} onTop={onTop} pageWidth={pageWidth} section="light"/>
    <Faq strings={StringRes(lang)} onTop={onTop} pageWidth={pageWidth} section="dark"/>
    <Gallery strings={StringRes(lang)} onTop={onTop} pageWidth={pageWidth} section="light"/> 
    <Contact strings={StringRes(lang)} onTop={onTop} pageWidth={pageWidth} section="dark"/>  
    <Footer />  
    </div>
  );
}

export default App;

import React from 'react';

import { useState } from 'react';
import { createPortal } from 'react-dom';


import { Col } from "react-bootstrap";

import GalleryModal from './GalleryModal';

function GalleryItem(prop){
    const [showFullPhoto, setShowFullPhoto] = useState(false);
//    const showModal = () => setShow(true);

    return (
        <> 
        <Col sm={12} md={6} lg={4}>
        <div className="gallery" onClick={() =>{
            setShowFullPhoto(true);       
        }}>
        <div className="gallery-photo"><img className='img-fluid' src={"../"+prop.tumb} alt={prop.alt}></img> </div>     
        </div>
        
        
        </Col>

        {showFullPhoto && createPortal(
            <GalleryModal 
                strings={prop.strings}
                photo={prop.photo} 
                onClose={() => setShowFullPhoto(false)} /> ,
            document.body
        )}
        </>
    );
}

export default GalleryItem;
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function GalleryModal(prop) {

  return (
      <Modal        
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered      
      show={true} onHide={prop.onClose}>
        <div className='modal-background'>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body><img className='photo-modal' src={prop.photo} alt="full size image" /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={prop.onClose}>
          {prop.strings.galleryModal.closeButton}
          </Button>    
        </Modal.Footer>

        </div>
       
      </Modal>

  );
}

export default GalleryModal;
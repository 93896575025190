import React from 'react';
import {Container, Row} from 'react-bootstrap';
import Title from './SectionTitle';
import Item from './ServiceItem';
import { FaPhone, FaEnvelope, FaAlignJustify } from 'react-icons/fa';


function Section(prop){
    let scrollOffsetClass = "";
    if (prop.onTop && prop.pageWidth<=990){
        scrollOffsetClass = "scroll-offset-fix";
    }


    return (
        <div id="service" className={scrollOffsetClass} style={prop.section==='dark'? {backgroundColor:'#fef5dd'}:null} >
        <Container className="section-padding">
        <Title title={prop.strings.service.sectionName}/>
        <div className='service-description'>
            
            <div className='text-justify'>{prop.strings.service.mainText}</div>
        <div className='text-center text-justify text-padding-top' >{prop.strings.service.callmenow}: </div>
        <div className='text-center'><div className='fa-phone-270 inline '> <FaPhone /> </div><div className='call-me  inline'>&nbsp; +&#51;&#55;&#48; 6&#55;304&#55;2&#55;</div></div>
        <div className='text-center text-justify text-padding-top' >{prop.strings.service.mailmenow}: </div>
        <div className='text-center'><div className='fa-mail-2 inline '> <FaEnvelope /> </div><div className='call-me  inline'>&nbsp; kaunetraliukas&#64;&#103;&#109;ai&#108;.com</div></div>
        </div>
            <Row >
                <Item 
                    photo="moto t.png"
                    alt="motocycle-logo"
                    title={prop.strings.service.motocycleHeader}
                    description={prop.strings.service.motocycleText}
                />
                <Item 
                    photo="auto b t.png"
                    alt="automobile-logo"
                    title={prop.strings.service.carHeader}
                    description={prop.strings.service.carText}
                />
                 <Item 
                    photo="offroad-auto t.png"
                    alt="offroad-auto-logo"
                    title={prop.strings.service.offroadHeader}
                    description={prop.strings.service.offroadText}
                />
                <Item 
                    photo="mikro b t.png"
                    alt="microbus-logo"
                    title={prop.strings.service.busHeader}
                    description={prop.strings.service.busText}
                />
                 <Item 
                    photo="other b t.png"
                    alt="other-transport"
                    title={prop.strings.service.otherHeader}
                    description={prop.strings.service.otherText}
                />
                 <Item 
                     photo="other-2 b t.png"
                    alt="other"
                    title={prop.strings.service.goodsHeader}
                    description={prop.strings.service.goodsText}
                />               
                 <Item 
                    photo="sos t.png"
                    alt="sos"
                    title={prop.strings.service.emergencyHeader}
                    description={prop.strings.service.emergencyText}
                />
                <Item 
                    photo="247 t.png"
                    alt="all-time"
                    title={prop.strings.service.anyTimeHeader}
                    description={prop.strings.service.anyTimeText}
                />
            </Row>
        </Container>
        </div>
    )
} 

export default Section;
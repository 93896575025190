import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import NotFoundHandler from './NotFoundHandler';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div>
        <Router>
      <Routes>
        <Route path='/' element={<App />} />
        {/* <Route path='/en' element={<App />} />
        <Route path='/rus' element={<App />} />
        <Route path='/lt' element={<App />} /> */}
        <Route path='*' element={<NotFoundHandler/>} />
      </Routes>
    </Router>
    {/* <App /> */}
  </div>
  // <React.StrictMode>
  //    <Router>
  //     <Routes>
  //       <Route path='/' element={<App />} />
  //       <Route path='*' element={<NotFoundHandler/>} />
  //     </Routes>
  //   </Router>
  //   {/* <App /> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Container,Row, Col } from "react-bootstrap";
import { FaPhone, FaEnvelope } from 'react-icons/fa';

function Topbar(prop){
    let hideContacts = false;
    if (prop.pageWidth < 400 ){
        hideContacts = true;
    }
    return (
   <div className="top-bar">
            <Container>   
            <Row className="header-top-left">
                <Col>
                <ul className="list-inline">
                <li> <a className='contact-ref' href="tel:+&#51;&#55;&#48;6&#55;304&#55;2&#55;"><FaPhone /> <span className={hideContacts?"hidden":""} >+&#51;&#55;&#48; 6&#55;304&#55;2&#55;</span> <span  className={!hideContacts?"hidden":""} >{prop.strings.topbar.callnow}</span></a> </li>
                <li> <a className='contact-ref' href="mailto:kaunetraliukas&#64;&#103;&#109;ai&#108;.com"><FaEnvelope /> <span className={hideContacts?"hidden":""} >kaunetraliukas&#64;&#103;&#109;ai&#108;.com</span><span  className={!hideContacts?"hidden":""} >{prop.strings.topbar.emailnow}</span></a> </li>
                </ul>
                </Col>               
            </Row>        
        </Container>
      </div>
 
    )
}

export default Topbar;